<template>
  <div class="dzs-acc-search">
    <div class="row">
      <div class="col-md-12 card">
        <div class="card-header">
          <h4>Gerenciar Plano para Cliente no Gateway</h4>
        </div>
        <div class="card-body row">
          <div class="col-md-3">
            <label class="control-label">Usuário</label>
            <fg-input v-model="search.username"/>
          </div>
          <div class="col-md-2">
            <fg-input label="Banco de Integração Entrada">
              <el-select size="large"
                         placeholder="Selecionar"
                         class="select-default"
                         v-model="search.integrationBankPixIn">
                <el-option :value="null"
                           class="select-default"
                           label="Todos"/>
                <el-option
                  class="select-default"
                  v-for="item in this.integrationBankPix"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"/>
              </el-select>
            </fg-input>
          </div><div class="col-md-2">
            <fg-input label="Banco de Integração Saída">
              <el-select size="large"
                         placeholder="Selecionar"
                         class="select-default"
                         v-model="search.integrationBankPixOut">
                <el-option :value="null"
                           class="select-default"
                           label="Todos"/>
                <el-option
                  class="select-default"
                  v-for="item in this.integrationBankPix"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"/>
              </el-select>
            </fg-input>
          </div>
          <div class="col-sm-2">
            <fg-input label="Exibir">
              <el-select
                class="select-default"
                v-model="pagination.perPage"
                placeholder="Per page">
                <el-option
                  class="select-default"
                  v-for="item in [25,50,100]"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </fg-input>
          </div>
          <div class="col-md-2">
            <p-button type="success" style="margin-top: 25px;"
                      @click="find">
              <i slot="label" class="nc-icon nc-check-2"></i>
              Buscar
            </p-button>
          </div>
          <div v-show="editModal" class="col-md-12 card">
            <div class="card-header">
              <h6>Editar plano para cliente</h6>
            </div>
            <div class="card-body row">
              <div class="col-md-2">
                <label class="control-label">Usuário</label>
                <fg-input v-model="clientToEdit.username"/>
              </div>
              <div class="col-md-2">
                <fg-input label="Banco de Integração Entrada">
                  <el-select size="large"
                             placeholder="Selecionar"
                             class="select-default"
                             v-model="clientToEdit.integrationBankPixIn">
                    <el-option :value="null"
                               class="select-default"
                               label="Padrão"/>
                    <el-option
                      class="select-default"
                      v-for="item in this.integrationBankPix"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"/>
                  </el-select>
                </fg-input>
              </div>
              <div class="col-md-2">
                <fg-input label="Banco de Integração Saída">
                  <el-select size="large"
                             placeholder="Selecionar"
                             class="select-default"
                             v-model="clientToEdit.integrationBankPixOut">
                    <el-option :value="null"
                               class="select-default"
                               label="Padrão"/>
                    <el-option
                      class="select-default"
                      v-for="item in this.integrationBankPix"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"/>
                  </el-select>
                </fg-input>
              </div>
              <div class="col-md-2" style="display: flex; flex-direction: column; align-items: center" >
                <label>Validação de Documento</label>
                <p-checkbox v-model="clientToEdit.documentValidationConfig"
                          :checked="!!clientToEdit.documentValidationConfig"/>
              </div>
              <div class="col-md-1" style="display: flex; flex-direction: column; align-items: center">
                <label>API Ativa</label>
                <p-checkbox v-model="clientToEdit.enabledApi"
                          :checked="!!clientToEdit.enabledApi"/>
              </div>
              <div class="col-md-2">
                <p-button type="info" style="margin-top: 25px;"
                          @click="save">
                  <i slot="label" class="nc-icon nc-check-2"></i>
                  Salvar
                </p-button>
              </div>
            </div>
          </div>
          <div class="col-sm-12 mt-2">
            <el-table class="table-striped"
                      :data="tableData"
                      style="width: 100%">
              <el-table-column :min-width="60" label="Usuário">
                <template slot-scope="props">
                    <span>
                      <span><b>@{{props.row.username}}</b></span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="70" label="Banco de Integração Entrada">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.integrationBankPixIn}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="70" label="Banco de Integração Saída">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.integrationBankPixOut}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="70" label="Validação de Documento" >
                <template slot-scope="props" >
                  <p-checkbox :checked="!!props.row.documentValidationConfig" disabled />
                </template>
              </el-table-column>
              <el-table-column :min-width="70" label="API Ativa" >
                <template slot-scope="props" >
                  <p-checkbox :checked="!!props.row.enabledApi" disabled />
                </template>
              </el-table-column>
              <el-table-column :width="70" fixed="right" class-name="td-actions">
                <template slot-scope="props">
                  <p-button type="info" size="sm" icon title="Editar"
                            @click="edit(props.row)">
                    <i class="fas fa-edit"></i>
                  </p-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="col-sm-6 pagination-info">
            <p class="category">EXIBINDO DO {{pagination.fromNumber}} AO {{pagination.toNumber}}, DE
              {{pagination.totalNumber}} REGISTROS.</p>
          </div>
          <div class="col-sm-6">
            <p-pagination class="pull-right"
                          v-model="pagination.currentPage"
                          :per-page="pagination.perPage"
                          :total="pagination.totalNumber"
                          :click="this.loadData">
            </p-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import {Option, Select, Table, TableColumn} from 'element-ui'
import PPagination from 'src/components/UIComponents/Pagination.vue'
import {postWs} from "src/ws.service"
import swal from 'sweetalert2'
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Select)
Vue.use(Option)
export default {
  components: {
    PPagination
  },
  data() {
    return {
      search: {
        username: '',
        integrationBankPixIn: '',
        integrationBankPixOut: '',
      },
      editModal: false,
      clientToEdit: {
        username: '',
        integrationBankPixIn: '',
        integrationBankPixOut: '',
        documentValidationConfig: '',
        enabledApi: ''
      },
      integrationBankPix: [],
      tableData: [],
      pagination: {
        perPage: 25,
        currentPage: 1,
        pageMaxNumber: 1,
        fromNumber: 0,
        toNumber: 0,
        totalNumber: 0
      }
    }
  },
  watch:{
    '$route': 'resetDefaultValues'
  },

  mounted() {
    this.loadData()
  },
  methods: {
    resetDefaultValues(){
      this.clientToEdit.username = ''
      this.clientToEdit.integrationBankPixIn = ''
      this.clientToEdit.integrationBankPixOut = ''
      this.clientToEdit.documentValidationConfig = false
      this.clientToEdit.enabledApi = false
      this.editModal = false
      this.search.username = ''
      this.search.integrationBank = null
      this.pagination.currentPage = 1
      this.pagination.perPage = 25
      this.pagination.pageMaxNumber = 1
      this.pagination.fromNumber = 0
      this.pagination.toNumber = 0
      this.pagination.totalNumber = 0
      this.tableData = [];
      this.loadData()
    },
    find() {
      this.pagination.currentPage = 1
      this.loadData()
    },
    edit(client){
      this.clientToEdit.username = client.username
      this.clientToEdit.integrationBankPixIn = client.integrationBankPixIn
      this.clientToEdit.integrationBankPixOut = client.integrationBankPixOut
      this.clientToEdit.documentValidationConfig = client.documentValidationConfig
      this.clientToEdit.enabledApi = client.enabledApi

      this.editModal = true;
    },
    save(){
      const findIdByName = (name) => {
        if(name != null){
          const item = this.integrationBankPix.find((obj) => obj.name === name.split('-')[0]);
          return item ? item.id : name;
        }
        return null
      };
      this.clientToEdit.integrationBankPixIn = findIdByName(this.clientToEdit.integrationBankPixIn);
      this.clientToEdit.integrationBankPixOut = findIdByName(this.clientToEdit.integrationBankPixOut);
      postWs("/bo/gateway/save-client-gateway-plan",
        true, null,
        {
          username: this.clientToEdit.username,
          integrationBankPixIn: this.clientToEdit.integrationBankPixIn,
          integrationBankPixOut: this.clientToEdit.integrationBankPixOut,
          documentValidationConfig: this.clientToEdit.documentValidationConfig,
          enabled: this.clientToEdit.enabledApi,
        }, () =>{
          swal({
            type: "success",
            title: "Alterado com sucesso!"
          })
          this.resetDefaultValues()
        },
        (error) => {
          console.log(error)
          if (error.status === 404){
            swal({
              title: 'Aviso!',
              text: 'Este usuário não possui um registro de credenciais API para serem habilitadas ou desabilitadas!',
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
          }else{
            swal({
              title: 'Aviso!',
              text: 'Erro ao alterar dados. Tente novamente!',
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
          }
        })
    },
    loadData() {
      postWs("/bo/gateway/list-external-bank", true, null, null,
        this.loadIntegrationBankList,
        (response) => {
          console.log(response)
        })
      postWs("/bo/gateway/list-gateway-plan",
        true, null,
        {
          username: this.search.username,
          integrationBankPixIn: this.search.integrationBankPixIn,
          integrationBankPixOut: this.search.integrationBankPixOut,
          pageNumber: (this.pagination.currentPage - 1),
          pageSize: this.pagination.perPage
        }, this.successLoadData,
        (error) => {
        console.log(error)
          swal({
            title: 'Aviso!',
            text: 'Erro ao buscar dados. Tente novamente!',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        })
      return this.pagination.currentPage
    },
    loadIntegrationBankList(response){
      this.integrationBankPix = response.data
      this.integrationBankPixOut = response.data
    },
    successLoadData(response) {
      if(response.status === 204){
        swal({
            title: 'Aviso!',
            type: "warning",
            text: 'Nenhum registro encontrado!',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
        })
      }else{
        this.tableData = response.data.plans
        this.pagination.totalNumber = response.data.totalNumber
        this.pagination.pageMaxNumber = response.data.pageMaxNumber
        if (this.pagination.totalNumber > 0) {
          this.pagination.fromNumber = ((this.pagination.perPage * (this.pagination.currentPage - 1)) + 1)
          this.pagination.toNumber = ((this.pagination.fromNumber + this.tableData.length) - 1)
        } else {
          this.pagination.fromNumber = 0
          this.pagination.toNumber = 0
        }
      }
    }
  }
}
</script>
<style lang="scss">
.dzs-acc-search {
  .el-table .td-actions {
    button.btn {
      margin-right: 5px;
    }
  }

  .input-group .form-control {
    padding-left: 2px !important;
  }

  .input-group .input-group-prepend .input-group-text {
    padding: 5px 0 5px 10px !important;
    font-size: 14px !important;
  }

  .cell {
    font-size: 12px !important;
  }

  th {
    color: black !important;
  }
}
</style>
