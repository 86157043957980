<template>
  <div class="dzs-boleto">
    <div class="row">
      <div class="col-md-12">
        <card>
          <h4 slot="header" class="card-title">
            Entrada de boleto por Período <span v-if="isBackoffice">(<span v-if="username != null"
                                                                                style="font-weight: bolder"> @{{
              username
            }}</span> )</span>
          </h4>
          <div class="row">
            <div class="col-md-2">
              <div class="row" style="margin-left: 0px">
                <label>Data Início</label>
              </div>
              <div class="row" style="margin-left: 0px">
                <el-date-picker v-model="startDate" type="date" placeholder="Escolha uma data"
                                :picker-options="dateOptsStart" @input="validatePeriod"
                                format="dd/MM/yyyy">
                </el-date-picker>
              </div>
            </div>
            <div class="col-md-2">
              <div class="row" style="margin-left: 0px">
                <label>Data Fim</label>
              </div>
              <div class="row" style="margin-left: 0px">
                <el-date-picker v-model="endDate" type="date" placeholder="Escolha uma data"
                                :picker-options="dateOptsEnd"
                                format="dd/MM/yyyy">
                </el-date-picker>
              </div>
            </div>
            <div class="col-md-3">
              <fg-input type="text" label="Nome do pagador" v-model="payerName"></fg-input>
            </div>
            <div class="col-md-3">
              <fg-input type="text" label="Documento do pagador" v-model="payingDocument"
                        v-mask="['###.###.###-##', '##.###.###/####-##']"></fg-input>
            </div>
            <div class="col-md-2">
              <label class="control-label">Valor do Documento</label>
              <fg-input placeholder="0,00" v-money="'money'" type="tel"
                        v-model="valueDocument">
              </fg-input>
            </div>
          </div>
          <div class="row">
            <div class="col-md-2">
              <fg-input label="Status">
                <el-select
                  class="select-default"
                  v-model="status"
                  placeholder="Status">
                  <el-option class="select-default" label="Todos" value=""></el-option>
                  <el-option class="select-default" label="Não pago" value="0"></el-option>
                  <el-option class="select-default" label="Pago" value="1"></el-option>
                </el-select>
              </fg-input>
            </div>
            <div class="col-md-2">
              <fg-input label="Exibir">
                <el-select
                  class="select-default"
                  v-model="pagination.perPage"
                  placeholder="Por Página">
                  <el-option
                    class="select-default"
                    v-for="item in [50, 100, 200]"
                    :key="item"
                    :label="item"
                    :value="item">
                  </el-option>
                </el-select>
              </fg-input>
            </div>
            <div class="col-md-4">
              <p-button @click.prevent="search" style="margin-top: 24px">
                <i class="fas fa-search-dollar"></i> BUSCAR
              </p-button>
              <p-button type="success" @click.prevent="generateCsv" v-show="this.timelineItems.length > 0" style="margin-top: 24px; margin-left: 24px">
                <i class="fas fa-file-invoice"></i> Exportar CSV
              </p-button>
            </div>
          </div>
        </card>
      </div>
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-6">
            <div class="row">
              <div class="col">
                <div class="card card-stats">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-5 col-md-4">
                        <div class="icon-big text-center text-success">
                          <i class="fa fa-money"></i>
                        </div>
                      </div>
                      <div class="col-7 col-md-8">
                        <div class="numbers">
                          <p class="card-category ">Número de Boletos criados</p>
                          <p class="card-title ">{{ this.numberOfBoleto }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer">
                    <hr>
                    <div class="stats" style="color: rgb(12, 7, 45); font-size: 13px;"> Valor Total: R$
                      {{ toMoney(this.valueOfBoleto) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="card card-stats">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-5 col-md-4">
                        <div class="icon-big text-center text-success">
                          <i class="fa-solid fa-circle-exclamation"></i>
                        </div>
                      </div>
                      <div class="col-7 col-md-8">
                        <div class="numbers">
                          <p class="card-category ">Número de Boletos não pagos</p>
                          <p class="card-title ">{{ this.numberOfBoletoCanceled }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer">
                    <hr>
                    <div class="stats" style="color: rgb(12, 7, 45); font-size: 13px;"> Valor Total: R$
                      {{ toMoney(this.valueOfBoletoCanceled) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="card card-stats">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-5 col-md-4">
                        <div class="icon-big text-center text-success">
                          <i class="fa-solid fa-hand-holding-dollar"></i>
                        </div>
                      </div>
                      <div class="col-7 col-md-8">
                        <div class="numbers">
                          <p class="card-category ">Número de Boletos Pagos</p>
                          <p class="card-title ">{{ this.numberOfBoletoPaid }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer">
                    <hr>
                    <div class="stats" style="color: rgb(12, 7, 45); font-size: 13px;"> Valor Total: R$
                      {{ toMoney(this.valueOfBoletoPaid) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div style="height: 100%; max-height: 100%; margin: 0 !important" class="row">
              <chart-card class="flex-grow-1" :chart-data="chartData" chart-id="pix-relation" chart-type="Pie"
                          title="Boleto" description="Relação de Boletos Pagos/Não Pagos">
                <template slot="header">
                  <p class="card-category">Relação de Boletos Pagos/Não Pagos</p>
                </template>
              </chart-card>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12" v-if="timelineItems.length>0"
           style="margin: 0 auto">
        <card>
          <div class="card-body row">
            <div class="col-sm-12 mt-2">
              <el-table class="table-striped"
                        :data="timelineItems"
                        style="width: 100%">
                <el-table-column :min-width="80" label="Emitido">
                  <template slot-scope="props">
                    <span>
                      <span>{{props.row.created}}</span>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="90" label="Valor">
                  <template slot-scope="props">
                    <span>
                      <span><b>R$</b> {{props.row.value}}</span>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="80" label="Venc.">
                  <template slot-scope="props">
                    <span>
                      <span>{{props.row.dueDate}}</span>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="90" label="Status">
                  <template slot-scope="props">
                    <span>
                      <i class="fas fa-circle" :style="'color: #'+statusToColor(props.row.status)"></i>
                      <span> {{statusToLabel(props.row.status)}}</span>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="200" label="Pagador">
                  <template slot-scope="props">
                    <span>
                      <span>{{props.row.payerName}}</span>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="200" label="Documento do Pagador">
                  <template slot-scope="props">
                    <span>
                      <span>{{formatPayerDocument(props.row.payerSocialNumber) }}</span>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :width="150" fixed="right" class-name="td-actions">
                  <template slot-scope="props" v-if="props.row.status == 'WAITING'">
                    <p-button type="danger" size="sm" icon title="Cancelar boleto"
                              @click="cancelBoleto(props.row.id)">
                      <i class="fas fa-ban"></i>
                    </p-button>
                    <p-button type="info" size="sm" icon title="Exibir código de barras"
                              @click="showBarcode(props.row)">
                      <i class="fas fa-barcode"></i>
                    </p-button>
                    <p-button type="info" size="sm" icon title="Imprimir"
                              @click="printBoleto(props.row.id)">
                      <i class="fas fa-print"></i>
                    </p-button>
                  </template>
                </el-table-column>
                <!--<el-table-column :min-width="20" label="">
                  <template slot-scope="item">
                    <span>
                      <span>
                        <p-button @click="showBoletoCode(item.row.brCode)"
                                  class="btn btn-info" title="Ver código Boleto">
                          <i class="fas fa-file-invoice fa-2x" style="color: #0c072d;"/>
                        </p-button>
                      </span>
                    </span>
                  </template>
                </el-table-column>-->
              </el-table>
            </div>
            <div class="col-sm-6 pagination-success">
              <p class="category">EXIBINDO DO {{ pagination.fromNumber }} AO {{ pagination.toNumber }}, DE
                {{ pagination.totalNumber }} REGISTROS.</p>
            </div>
            <div class="col-sm-6">
              <p-pagination class="pull-right"
                            v-model="pagination.currentPage"
                            :per-page="pagination.perPage"
                            :total="pagination.totalNumber"
                            :click="this.search">
              </p-pagination>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import {DatePicker} from "element-ui";
import {Badge, TimeLine, TimeLineItem} from "@/components/UIComponents";
import PPagination from "@/components/UIComponents/Pagination";
import ChartCard from "@/components/UIComponents/Cards/ChartCard";
import {isNullOrEmpty, toMoney, moneyToFloat} from "@/util/core.utils";
import {daysBetween, formatDate} from "@/util/date.utils";
import swal from "sweetalert2";
import {callWs} from "@/ws.service";
import download from "downloadjs";
export default {
  name: "Boleto.vue",
  components: {
    [DatePicker.name]: DatePicker,
    TimeLine,
    TimeLineItem,
    Badge,
    PPagination,
    ChartCard
  },
  data() {
    return {
      startDate: new Date(),
      endDate: new Date(),
      timelineItems: [],
      username: null,
      isBackoffice: false,
      status: '',
      payerName: null,
      payingDocument: null,
      valueDocument: 0,
      dateOptsStart: {
        disabledDate: this.disabledStartDate,
        shortcuts: [{
          text: 'Hoje',
          onClick(picker) {
            const date = new Date()
            picker.$emit('pick', date)
            this.endDate = date
          }
        },
          {
            text: 'Ontem',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24)
              picker.$emit('pick', date)
              this.endDate = date
            }
          },
          {
            text: 'Anteontem',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 48)
              picker.$emit('pick', date)
              this.endDate = date
            }
          }]
      },
      dateOptsEnd: {
        disabledDate: this.disabledEndDate
      },
      pagination: {
        perPage: 50,
        currentPage: 1,
        pageMaxNumber: 1,
        fromNumber: 0,
        toNumber: 0,
        totalNumber: 0
      },
      numberOfBoleto: 0,
      numberOfBoletoCanceled: 0,
      numberOfBoletoPaid: 0,
      valueOfBoleto: 0,
      valueOfBoletoCreated: 0,
      valueOfBoletoPaid: 0,
    }
  },
  created() {
    if (isNullOrEmpty(this.$route.params.username)) {
      this.isBackoffice = false
      this.username = localStorage.getItem("usernameDisplay")
    } else {
      this.isBackoffice = true
      this.username = this.$route.params.username
    }
  },
  computed: {
    chartData: function () {
      return {
        labels: ['Boletos Não Pagos (%)', 'Boletos Pagos (%)'],
        datasets: [{
          label: "Boletos Pagos",
          pointRadius: 0,
          pointHoverRadius: 0,
          backgroundColor: ['#f69090', '#6bd098'],
          borderWidth: 0,
          data: [this.calculatePercent(this.numberOfBoletoCanceled, this.numberOfBoleto), this.calculatePercent(this.numberOfBoletoPaid, this.numberOfBoleto)]
        }],
      }
    }
  },
  methods: {
    toMoney,
    moneyToFloat,
    calculatePercent(value, relativeOf) {
      const percent = (value * 100) / relativeOf;
      return percent.toFixed(2);
    },
    formatPayerDocument(payerSocialNumber) {
      if(!payerSocialNumber) return '';
      const sanitizedNumber = payerSocialNumber.replace(/\D/g, '');

      if (sanitizedNumber.length === 11) {
        return sanitizedNumber.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
      } else if (sanitizedNumber.length === 14) {
        return sanitizedNumber.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
      }

      return payerSocialNumber;
    },
    search() {
      if (daysBetween(this.startDate, this.endDate) > 31) {
        swal({
          title: 'Aviso!',
          text: 'O período selecionado deve ser de no máximo 31 dias!',
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
      } else {
        callWs('/boleto/last-requested-boletos-report',
          'POST', null, true, null,
          {
            username: this.username,
            startDate: formatDate(this.startDate, "DD/MM/YYYY"),
            endDate: formatDate(this.endDate, "DD/MM/YYYY"),
            payerName: this.payerName,
            payingDocument: this.payingDocument,
            valueDocument: moneyToFloat(this.valueDocument),
            status: this.status,
            pageNumber: this.pagination.currentPage - 1,
            pageSize: this.pagination.perPage
          },
          this.loadTimeline,
          this.failSearch)
      }
    },
    loadTimeline(response) {
      this.timelineItems = response.data.list
      this.numberOfBoleto = response.data.numberOfBoleto
      this.numberOfBoletoCanceled = response.data.numberOfBoletoCanceled
      this.numberOfBoletoPaid = response.data.numberOfBoletoPaid
      this.valueOfBoleto = response.data.valueOfBoleto
      this.valueOfBoletoCanceled = response.data.valueOfBoletoCanceled
      this.valueOfBoletoPaid = response.data.valueOfBoletoPaid
      this.pagination.totalNumber = response.data.totalNumber
      this.pagination.pageMaxNumber = response.data.pageMaxNumber
      if (this.pagination.totalNumber > 0) {
        this.pagination.fromNumber = ((this.pagination.perPage * (this.pagination.currentPage - 1)) + 1)
        this.pagination.toNumber = ((this.pagination.fromNumber + this.timelineItems.length) - 1)
      } else {
        this.pagination.fromNumber = 0
        this.pagination.toNumber = 0
      }
      if (this.timelineItems.length <= 0) {
        swal({
          title: 'Não encontrado',
          text: 'No período selecionado não existem transações.',
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
        return
      }
    },
    failSearch(error) {
      swal(
        {
          title: 'Erro Inesperado',
          text: 'Ocorreu um erro inesperado ao buscar os dados. Tente novamente.',
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        }
      )
    },
    validatePeriod() {
      this.endDate = this.startDate
    },
    disabledStartDate(date) {
      let now = new Date()
      return date > now
    },
    disabledEndDate(date) {
      let now = new Date()
      return date > now || date < this.startDate
    },
    showPixBrCode(code) {
      swal({
        title: 'Código do Boleto',
        text: code,
        buttonsStyling: true,
        confirmButtonClass: 'btn btn-info btn-fill',
        width: '40rem'
      })
    },
    generateCsv() {
      if(daysBetween(this.startDate, this.endDate) > 31){
        swal({
          title: 'Aviso!',
          text: 'O período selecionado deve ser de no máximo 31 dias!',
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
        return
      }
      callWs("/boleto/last-requested-boletos-report-csv",
        "POST", null, true, null,
        {
          username: this.username,
          startDate: formatDate(this.startDate, "DD/MM/YYYY"),
          endDate: formatDate(this.endDate, "DD/MM/YYYY"),
          payerName: this.payerName,
          payingDocument: this.payingDocument,
          status: this.status,
          pageNumber: this.pagination.currentPage - 1,
          pageSize: this.pagination.perPage,
          valueDocument: moneyToFloat(this.valueDocument),
        },
        this.successGenerateCsv,
        this.failGenerateCsv
      )
    },
    successGenerateCsv(response) {
      try {
        download("data:application/csv;base64,"+response.data, "extrato_suitpay_"+formatDate(this.startDate, 'DD/MM/YYYY')+"_a_"+formatDate(this.endDate, 'DD/MM/YYYY')+".csv", "text/csv")
      } catch (ex) {
        swal({
          title: 'Erro!',
          text: "Erro ao gerar o extrato em CSV, favor tentar novamente.",
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
      }
    },
    statusToLabel(status) {
      if ('PAID' == status) {
        return 'Pago'
      } else if ('WAITING' == status) {
        return 'Pendente'
      } else if ('CANCELED' == status) {
        return 'Cancelado'
      } else {
        return 'Vencido'
      }
    },
    statusToColor(status) {
      if ('PAID' == status) {
        return '6bd098'
      } else if ('WAITING' == status) {
        return 'fbc658'
      } else {
        return 'ef8157'
      }
    },
    failGenerateCsv() {
      swal({
        title: 'Aviso!',
        text: 'Erro ao gerar o CSV do extrato, tente novamente!',
        buttonsStyling: true,
        confirmButtonClass: 'btn btn-warning btn-fill',
        type: 'warning'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.dzs-boleto {
  .btn.btn-info.btn-default {
    float: right;
    background: none !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  .btn.btn-default {
    background-color: #17a2b8 !important;
  }

  .btn.btn-default:hover {
    background-color: #0a7282 !important;
  }

  .advanced-filter {
    gap: .5rem;
  }

  .advanced-filter div {
    margin: 0 !important;
  }

  .card-footer {
    padding-bottom: 15px !important;
  }

  .cell {
    font-size: 10px !important;
  }
}
</style>
