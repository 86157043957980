import { render, staticRenderFns } from "./ClientDetailsSalePerson.vue?vue&type=template&id=77b3d40d"
import script from "./ClientDetailsSalePerson.vue?vue&type=script&lang=js"
export * from "./ClientDetailsSalePerson.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports