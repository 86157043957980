<template>
  <div class="dzs-acc-edit">
    <card>
      <div class="row">
        <div class="col-md-12">
          <card>
            <div class="card-header">
              <h6>
                Novo Atendimento Compliance
              </h6>
            </div>
            <div class="row card-body col-md-6"  style="width: 50%; margin: auto">
              <div class="row col-md-12">
                <label class="control-label">Descrição do Atendimento</label>
                <textarea style="width: 100%" rows=5 v-model="description" name="Descrição"
                           :error="getError('Descrição')" maxlength="2000" />
              </div>
              <div class="col-md-8">
                <fg-input label="Identificação do Documento" name="Identificação" v-model="newDocName" type="text"
                          data-vv-scope="addDocScope" :error="getError('addDocScope.Identificação')"/>
              </div>
              <div class="col-md-4">
                <p-button style="margin-top: 22px" @click.prevent="function (){$refs['selectFile'].click()}">
                  <i class="fa-regular fa-image"></i> {{ newFileName }}
                </p-button>
                <input v-validate="{required: true}" data-vv-scope="addDocScope" :error="getError('addDocScope.Arquivo')" name="Arquivo" type="file" ref="selectFile" style="display: none" accept=".png, .jpg, .jpeg, .pdf" @change="function (event){validateFile(event)}"/>
              </div>
              <div class="col-md-3" style="width: 50%; margin: auto">
                <p-button style="margin-top: 22px" type="primary" round @click.prevent="addFile()">
                  <i class="fa fa-upload"/> Adicionar
                </p-button>
              </div>
              <div class="col-md-12" v-if="fileToSendList.length > 0">
                <card>
                  <div class="card-header">
                    <h6>
                      Documentos a enviar
                    </h6>
                  </div>
                  <div class="row card-body" style="width: 100%">
                    <div class="col-md-12">
                      <el-table class="table-striped"
                                :data="fileToSendList"
                                style="width: 100%">
                        <el-table-column :min-width="90" label="Data">
                          <template v-slot="props">
                            <span>{{props.row.date}}</span>
                          </template>
                        </el-table-column>
                        <el-table-column :min-width="100" label="Decrição">
                          <template v-slot="props">
                            <span>{{props.row.description}}</span>
                          </template>
                        </el-table-column>
                        <el-table-column :min-width="90" label="Identificação">
                          <template v-slot="props">
                            <span>{{props.row.docName}}</span>
                          </template>
                        </el-table-column>
                        <el-table-column :min-width="90" label="Nome do Arquivo">
                          <template v-slot="props">
                            <span>{{props.row.fileName}}</span>
                          </template>
                        </el-table-column>
                        <el-table-column :min-width="90" label="Ação">
                          <template v-slot="props">
                            <p-button type="primary" size="sm" icon title="Visualizar" style="margin-right: 5px" @click="viewHistory(props.row, false)">
                              <i class="fa-regular fa-eye"></i>
                            </p-button>
                            <p-button type="danger" size="sm" icon title="Remover arquivo" @click="remFile(props.row.id)">
                              <i class="fa-solid fa-xmark"></i>
                            </p-button>
                          </template>
                        </el-table-column>
                      </el-table>
                    </div>
                  </div>
                </card>
              </div>
            </div>
          </card>
          <div class="row">
            <div class="col-md-12">
              <card>
                <div class="card-header">
                  <h6>
                    Documentos Salvos
                  </h6>
                </div>
                <div class="row card-body">
                  <div class="col-md-12">
                    <el-table class="table-striped"
                              :data="fileList"
                              style="width: 50%; margin: auto">
                      <el-table-column :min-width="90" label="Data">
                        <template v-slot="props">
                          <span>{{props.row.date}}</span>
                        </template>
                      </el-table-column>
                      <el-table-column :min-width="90" label="Descrição">
                        <template v-slot="props">
                          <span>{{props.row.description}}</span>
                        </template>
                      </el-table-column>
                      <el-table-column :min-width="90" label="Identificação">
                        <template v-slot="props">
                          <span>{{props.row.identification}}</span>
                        </template>
                      </el-table-column>
                      <el-table-column :min-width="30" label="Ação">
                        <template v-slot="props">
                          <p-button type="primary" size="sm" icon title="Visualizar" @click="viewDoc(props.row.id)">
                            <i class="fa-regular fa-eye"></i>
                          </p-button>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
              </card>
            </div>
          </div>
        </div>
      </div>
    </card>
  </div>
</template>
<script>
import {callWs, getWs, failWs, postWs} from 'src/ws.service'
import swal from "sweetalert2";
import PPagination from "@/components/UIComponents/Pagination";

export default {
  name: 'commercial-client-compliance-history',
  props: {
    username: null,
  },
  components: {
  },
  created() {
    this.$emit("saveInterface", {save: () => this.save()})
    this.loadFiles();
  },
  data() {
    return {
      newDocName:'',
      newFileName:'Escolha um Arquivo',
      newFile: null,
      fileMaxSize: 5,
      fileToSendList:[],
      fileList: [],
      description: '',
      attendant: null
    }
  },
  methods: {
    validateFile(event) {
      if (event.target.files.length <= 0) {
        return
      } else if (event.target.files[0].size > (this.fileMaxSize * 1024 * 1024)) {
        swal({
          title: 'Aviso!',
          text: 'A imagem deve possuir no máximo ' + this.fileMaxSize.toString() + 'MB',
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
        return
      }
      this.newFile = event.target.files[0]
      this.newFileName = event.target.files[0].name
    },
    addFile() {
      const isDescriptionFilled = !!this.description?.trim();
      const isFileSelected = !!this.newFile;

      const isValid = isDescriptionFilled || isFileSelected;

      if (!isValid) {
        this.$notify({
          type: 'error',
          message: 'Você deve preencher a Descrição ou selecionar um Arquivo.'
        });
        return;
      }

      this.fileToSendList.push({
        id: Date.now(),
        date: new Date().toLocaleString(),
        docName: this.newDocName,
        fileName: this.newFileName,
        file: this.newFile,
        description: this.description
      });

      this.newDocName = ''
      this.newFile = null
      this.newFileName = 'Escolha um Arquivo'
      this.description = ''
      this.$validator.reset({ scope: 'addDocScope' })
      this.$refs.selectFile.value = null
    },
    remFile(fileId){
      let indexToRemove = -1
      this.fileToSendList.some((file, index) =>{
        if(file.id === fileId){
          indexToRemove = index
          return true;
        }
      })
      if(indexToRemove >= 0){
        this.fileToSendList.splice(indexToRemove, 1)
      }
    },
    getError(fieldName) {
      return this.errors.first(fieldName)
    },
    loadFiles(){
      getWs("/bo/commercial/dashboard/getComplianceHistorys", true, {username: this.username},
        response => {
          if(typeof (response.data.response) == "undefined" ){
            console.log(response.data)
            this.fileList = response.data
          }
        }, failWs)
    },
    save(){
      if(this.fileToSendList.length > 0){
        this.doRecursiveSave(0)
      } else {
        this.$emit("saveFinished")
      }
    },
    doRecursiveSave(index){
      let formData = new FormData()
      formData.append("file", this.fileToSendList[index].file)
      callWs("/bo/commercial/dashboard/saveComplianceHistory", 'POST', {'Content-Type': 'multipart/form-data'}, true,
        {username: this.username, identification: this.fileToSendList[index].docName, description: this.fileToSendList[index].description},
        formData,
        response => {
          if(this.fileToSendList.length - 1 === index){
            this.$emit("saveFinished")
            this.loadFiles()
            this.fileToSendList = []
          } else {
            this.doRecursiveSave(index+1)
          }
        },error => console.log(error))
    },
    viewDoc(idDoc){
      getWs('/bo/commercial/dashboard/getComplianceClientDoc', true, {id: idDoc},
        response => {
          let fileType = response.data.fileType.replace(" ", "").toLowerCase()
          let fileData = null
          if(fileType == "") {
            fileData = ''
          } else if (fileType === 'pdf') {
            fileData = '<embed width="900" height="900" src="data:application/pdf;base64,' + response.data.image64 + '" />'
          } else if (fileType === 'jpg') {
            fileData = '<img src="data:image/jpeg;base64,' + response.data.image64 +'" />'
          } else {
            fileData = '<img src="data:image/' + fileType + ';base64,' + response.data.image64 + '" />'
          }

          swal({
            title: 'Documento!',
            html:
              '<div style="font-size: medium;">' +
              '     <span> ' + response.data.identification.replaceAll("\n", "<br>") +'</span>' +
              '   <hr>' +
              '   </br>' +
              '   <div style="text-align: center">' +
              '     <span style="font-size: 0.8571em;margin-bottom: 5px;color: #9A9A9A">Imagem: </span><br>' +
              '     <span style="overflow: auto"> ' + fileData + '</span>' +
              '   </div>' +
              '</div>' ,
            buttonsStyling: false,
            width: '50%',
            confirmButtonClass: 'btn btn-success btn-fill',
            type: 'info'
          })
        },
        error => {
          swal({
            title: 'Ops!',
            text: error.response.data.message,
            buttonsStyling: false,
            confirmButtonClass: 'btn btn-success btn-fill',
            type: 'error'
          })
        })
    },
    viewHistory(row, showSalePersonName){
      let content =
        '<div style="font-size: medium;">'
      if(showSalePersonName){
        content += '   Realizado por: ' + row.salePersonName
      }
      content +=
        '<br>' +
        '   Horário: ' + row.date +
        '   <hr>' +
        '   <br>' +
        '   <div style="text-align: left">' +
        '     <h6> Descrição </h6>'+
        '     <br>'+
        '     <span> ' + row.description.replaceAll("\n", "<br>") +'</span>' +
        '   </div>' +
        '</div>'

      swal({
        title: 'Histórico de Atendimento!',
        html: content,
        buttonsStyling: false,
        confirmButtonClass: 'btn btn-success btn-fill',
        type: 'info'
      })
    },
  }
}

</script>

<style lang="scss">
</style>
